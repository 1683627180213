import './src/styles/global.css';
import Modal from 'react-modal';
import * as Sentry from "@sentry/gatsby";
import React from 'react';
import RootElement from './src/components/root-element';

export const wrapRootElement = ({ element }) => {
    return <RootElement>{ element } </RootElement>;
};
export const onClientEntry = () => {
    // Set the root element of your application
    Modal.setAppElement('#___gatsby');
};

// const currentUrl = window.location.origin;

Sentry.init({
    dsn: "https://937b2c20d614d154e247494cc38077b0@o960554.ingest.sentry.io/4506235617673216",
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


export default { onClientEntry };
